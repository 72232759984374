import { useSettingsStore } from '../store';

export async function checkIgUsername(username, currentUsername = '') {
  if (!username) return true; // Skip check if username is empty
  if (username === currentUsername) return true; // Skip check if username hasn't changed
  
  const settingsStore = useSettingsStore();
  const settings = settingsStore;
  
  try {
    const response = await fetch(`${settings.BACK_URL}/ig/check_ig_username/${username}`);
    const data = await response.json();
    
    if (data.exists) {
      alert(`The Instagram username ${username} is already registered as a ${data.type}`);
      return false;
    }
    return true;
  } catch (error) {
    console.error('Error checking Instagram username:', error);
    return false;
  }
} 