<template>
  <div>
    <router-view></router-view>
  </div>
  <Footer />
</template>

<script setup>
import { onMounted, watch } from 'vue';
import { useSettingsStore } from './store';
import { useRoute, useRouter } from 'vue-router';
import Footer from "./components/Footer.vue";

const settingsStore = useSettingsStore();
const route = useRoute();
const router = useRouter();

onMounted(async () => {
  await router.isReady();
  checkRouteAndFetchSettings();
});

watch(() => route.path, checkRouteAndFetchSettings);
function checkRouteAndFetchSettings() {
  if (!route.path.includes('family_join') && !route.path.includes('family_create') && !route.path.includes('/ig') && !route.path.includes('reset') && !route.path.includes('family') && !route.path.includes('log') && !route.path.includes('linker')) {
    settingsStore.fetchSettings();
  }
}
</script>

<style scoped></style>
