<template>
  <div class="logs-container">
    <h2>Logs & Prompts</h2>
    <div class="logs-grid">
      <div v-for="(group, index) in strippedLogGroups" :key="index" class="log-group">
        <h3>{{ group.title }}</h3>
        <div class="links-container">
          <template v-if="group.promptUrls.length">
            <a @click.prevent="showLogContent(index)" class="link log-link">
              <!-- <span class="link-label">Log File</span> -->
              <span class="link-text">{{ group.logFile }}</span>
            </a>
            <a v-for="(prompt, idx) in group.promptUrls" :key="idx" :href="prompt.url" target="_blank"
              class="link prompt-link">
              <!-- <span class="link-label">Prompt</span> -->
              <span class="link-text">{{ prompt.file }}</span>
            </a>
          </template>
        </div>
        <div v-if="selectedLogIndex === index" class="log-content">
          <button @click="reloadLogContent(index)" class="reload-button">Reload</button>
          <pre>{{ logContent }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const logGroups = [
  {
    title: 'IG Intro Post',
    logUrl: 'https://dev.storycall.org/logs/ig_intro_log.txt',
    logFile: 'ig_intro_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_intro.txt',
      file: 'ig_intro.txt'
    }]
  },
  {
    title: 'IG Intro Post DALLE',
    logUrl: 'https://dev.storycall.org/logs/ig_intro_dalle_log.txt',
    logFile: 'ig_intro_dalle_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_intro_dalle.txt',
      file: 'ig_intro_dalle.txt'
    }]
  },
  {
    title: 'IG Intro Post omment',
    logUrl: 'https://dev.storycall.org/logs/ig_to_post_intro_log.txt',
    logFile: 'ig_to_post_intro_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_to_post_intro.txt',
      file: 'ig_to_post_intro.txt'
    }]
  },
  {
    title: 'IG DM',
    logUrl: 'https://dev.storycall.org/logs/ig_to_dm_text_log.txt',
    logFile: 'ig_to_dm_text_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_to_dm_text.txt',
      file: 'ig_to_dm_text.txt'
    }]
  },
  {
    title: 'IG DM Rec Intro',
    logUrl: 'https://dev.storycall.org/logs/ig_to_dm_rec_intro_log.txt',
    logFile: 'ig_to_dm_rec_intro_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_to_dm_rec_intro.txt',
      file: 'ig_to_dm_rec_intro.txt'
    }]
  },
  {
    title: 'IG DM Rec Outro', 
    logUrl: 'https://dev.storycall.org/logs/ig_to_dm_rec_outro_log.txt',
    logFile: 'ig_to_dm_rec_outro_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_to_dm_rec_outro.txt',
      file: 'ig_to_dm_rec_outro.txt'
    }]
  },
  {
    title: '',
    logUrl: '',
    logFile: '',
    promptUrls: []
  },  
  {
    title: '',
    logUrl: '',
    logFile: '',
    promptUrls: []
  },  
  {
    title: 'IG Post Comment',
    logUrl: 'https://dev.storycall.org/logs/ig_to_post_log.txt',
    logFile: 'ig_to_post_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_to_post.txt',
      file: 'ig_to_post.txt'
    }]
  },
  {
    title: 'Question Generate',
    logUrl: 'https://dev.storycall.org/logs/ig_to_app_question_log.txt',
    logFile: 'ig_to_app_question_log.txt',
    promptUrls: [
      {
        url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_to_app_question_system.txt',
        file: 'ig_to_app_question_system.txt'
      },
      {
        url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_to_app_question_user.txt',
        file: 'ig_to_app_question_user.txt'
      }
    ]
  },
  {
    title: 'Question Followup',
    logUrl: 'https://dev.storycall.org/logs/ig_to_app_followup_log.txt',
    logFile: 'ig_to_app_followup_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_to_app_followup_user.txt',
      file: 'ig_to_app_followup_user.txt'
    },
    {
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_to_app_followup_system.txt',
      file: 'ig_to_app_followup_system.txt'
    }]
  },  
  {
    title: 'Question DALL-E',
    logUrl: 'https://dev.storycall.org/logs/ig_to_app_dalle_log.txt',
    logFile: 'ig_to_app_dalle_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_to_app_dalle.txt',
      file: 'ig_to_app_dalle.txt'
    }]
  },
  {
    title: 'IG Post',
    logUrl: 'https://dev.storycall.org/logs/ig_from_app_answer_log.txt',
    logFile: 'ig_from_app_answer_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_from_app_answer.txt',
      file: 'ig_from_app_answer.txt'
    }]
  },

  {
    title: 'IG Post DALLE',
    logUrl: 'https://dev.storycall.org/logs/ig_from_app_answer_dalle_log.txt',
    logFile: 'ig_from_app_answer_dalle_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_from_app_answer_dalle.txt',
      file: 'ig_from_app_answer_dalle.txt'
    }]
  },
  {
    title: '',
    logUrl: '',
    logFile: '',
    promptUrls: []
  },
]

const strippedLogGroups = computed(() => {
  return logGroups.map(group => ({
    ...group,
    logFile: group.logFile.replace('.txt', ''),
    promptUrls: group.promptUrls.map(prompt => ({
      ...prompt,
      file: prompt.file.replace('.txt', '')
    }))
  }));
});

const selectedLogIndex = ref(null);
const logContent = ref('');

function showLogContent(index) {
  // if (selectedLogIndex.value === index) {
  //   selectedLogIndex.value = null;
  //   logContent.value = '';
  // } else {
  //   selectedLogIndex.value = index;
  //   fetchLogContent(strippedLogGroups.value[index].logUrl);
  // }

  const logUrl = strippedLogGroups.value[index].logUrl;
  window.open(logUrl, '_blank'); 
}

async function fetchLogContent(url) {
  try {
    const response = await fetch(url);
    logContent.value = await response.text();
  } catch (error) {
    logContent.value = 'Error loading log content.';
  }
}

function reloadLogContent(index) {
  const logUrl = strippedLogGroups.value[index].logUrl;
  const cacheBuster = `?t=${new Date().getTime()}`;
  fetchLogContent(logUrl + cacheBuster);
}
</script>

<style scoped>
.logs-container {
  padding: 20px;
  color: #ffffff;
  max-width: 1200px;
  margin: 0 auto;
}

.logs-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
}

.log-group {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;
}

h2 {
  color: #ffffff;
  margin-bottom: 2rem;
}

h3 {
  color: #ffffff;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.links-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  color: #e7f3ff;
  text-decoration: none;
  transition: all 0.2s ease;
  cursor: pointer;
}

.log-link {
  background: rgba(66, 153, 225, 0.1);
}

.prompt-link {
  background: rgba(72, 187, 120, 0.1);
}

.link:hover {
  transform: translateX(4px);
  background: rgba(255, 255, 255, 0.1);
}

.link-label {
  font-size: 0.8rem;
  opacity: 0.7;
}

.link-text {
  font-weight: 500;
}

.log-content {
  margin-top: 1rem;
  background: rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 6px;
  color: #ffffff;
  white-space: pre-wrap;
}

.reload-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #4a90e2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.reload-button:hover {
  background-color: #357ab8;
}

@media (max-width: 480px) {
  .logs-container {
    padding: 1rem;
  }

  .log-group {
    padding: 0.75rem;
  }

  .link {
    padding: 0.5rem 0.75rem;
  }
}
</style>
