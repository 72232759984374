<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <ConfigNavBar />
      <div class="bottom-margin"></div>
      <div class="grid-table-cell-wide column">
        <input type="text" placeholder="Name" v-model="callee.callee_name" />
      </div>
      <div class="grid-table-cell-wide row">
        <div style="display: flex; justify-content: space-between; width: 100%">
          +1 &nbsp;
          <input type="tel" placeholder="212" v-model="callee.area_code" maxlength="3"
            style="width: 20%; margin-right: 2%; text-align: center" title="Only numeric values are allowed." />
          <input type="tel" placeholder="555" v-model="callee.prefix" maxlength="3"
            style="width: 20%; margin-right: 2%; text-align: center" title="Only numeric values are allowed." />
          <input type="tel" placeholder="1234" v-model="callee.suffix" maxlength="4"
            style="width: 38%; text-align: center" title="Only numeric values are allowed." />
        </div>
      </div>
      <!-- Add this new input field for Instagram username -->
      <div class="grid-table-cell-wide column">
        <input type="text" placeholder="Instagram Username" v-model="callee.ig_username" />
      </div>
      {{ settings.formatUIString(ui_string.callee_instruct_title, { callee_name: callee_name }) }}
      <div class="grid-table-cell-wide column">
        <textarea :placeholder="ui_string.callee_instruct_default" rows="5" v-model="callee.callee_instruct"></textarea>
      </div>
      
      <div class="grid-table-cell-narrow column">
        <IgAuth @saveBeforeAuth="handleSaveBeforeAuth" />
      </div>

      <!-- Add new button for generating initial post -->
      <div class="grid-table-cell-narrow column">
        <button @click="generateInitialPost">Generate Initial Instagram Post</button>
      </div>

      <div class="grid-table-cell-narrow column">
        <button @click="setToAnswerCalls">{{ ui_string.callee_set_button }}</button>
      </div>
      <div class="grid-table-cell-narrow column">
        <button @click="() => calleeUpdate(callee)">{{ ui_string.callee_update_save_button }}</button>
      </div>
      <div class="grid-table-cell-narrow column">
        <button @click="() => calleeDelete(callee.id)"
          class="delete-button">{{ ui_string.callee_update_delete_button }}</button>
      </div>
      <div class="grid-table-cell-wide column">
        <div class="label-text">
          <div>{{ ui_string.callee_caller_qa }}</div>
        </div>
        <textarea placeholder="All messages with replies will be placed here" rows="5"
          v-model="qaLists[calleeIdUpdating]"></textarea>
      </div>
      <div class="bottom-margin"></div>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { storeToRefs } from 'pinia';
import ConfigNavBar from "./ConfigNavBar.vue";
import IgAuth from "./IgAuth.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import { useSettingsStore } from "../store";
import { checkIgUsername } from '../utils/instagram';

const router = useRouter();
const settings = useSettingsStore();
const { showModal, BACK_URL, family_id, family_instruct, callee_id, callee_name, caller_name, caller_id, callee_phone_number, caller_instruct, ui_string } = storeToRefs(settings);
const calleeIdUpdating = ref(router.currentRoute.value.params.callee_id);
const callee = ref([]);
const qaLists = ref({});
const igCallers = ref(['', '', '']);
const originalIgUsername = ref('');

const setToAnswerCalls = async () => {
  callee_id.value = callee.value.id;
  callee_name.value = callee.value.callee_name;
  callee_phone_number.value = callee.value.phone_number;
  await settings.saveSettings();
  router.push('/home');
};

const calleeGetById = async () => {
  try {
    const payload = {
      callee_id: calleeIdUpdating.value,
      caller_id: caller_id.value
    };
    const response = await axios.post(`${BACK_URL.value}/callee/callee_get_byid`, payload);
    callee.value = response.data;
    originalIgUsername.value = response.data.ig_username || '';
    
    if (callee.value.phone_number) {
      const phone = callee.value.phone_number;
      callee.value.area_code = phone.substring(2, 5);
      callee.value.prefix = phone.substring(5, 8);
      callee.value.suffix = phone.substring(8, 12);
      callee.value.ig_username = response.data.ig_username;
      callee.value.ig_callers = response.data.ig_callers || ['', '', ''];
    }
    await qaListGet(response.data.id);
  } catch (error) {
    console.error("Failed to fetch phone number:", error.response?.data?.detail[0]?.msg || error.message);
    alert(`Failed to fetch phone number: ${error.response?.data?.detail[0]?.msg || error.message}`);
  }
};

const calleeUpdate = async (callee) => {
  const usernameAvailable = await checkIgUsername(callee.ig_username, originalIgUsername.value);
  if (!usernameAvailable) return;

  showModal.value = true;
  // Validation for area code, prefix, and suffix
  const areaCodeValid = /^\d{3}$/.test(callee.area_code);
  const prefixValid = /^\d{3}$/.test(callee.prefix);
  const suffixValid = /^\d{4}$/.test(callee.suffix);
  if (!areaCodeValid || !prefixValid || !suffixValid) {
    alert("Please ensure the area code and prefix have exactly 3 digits, and the suffix has exactly 4 digits, all numeric.");
    showModal.value = false;
    return;
  }
  try {
    const payload = {
      ...callee,
      phone_number: `+1${callee.area_code}${callee.prefix}${callee.suffix}`,
      family_id: family_id.value,
      caller_id: caller_id.value,
      callee_id: calleeIdUpdating.value,
      callee_instruct: callee.callee_instruct,
      ig_username: callee.ig_username
    };
    // Update the API call to use PUT method and send the payload
    await axios.put(`${BACK_URL.value}/callee/callee_update`, payload);
    await settings.fetchCallee();
    router.push('/callee');
  } catch (error) {
    const errorMessage = error.response?.data?.detail || "Failed to update phone number.";
    console.error("Failed to update phone number:", errorMessage);
    alert(`Failed to update phone number: ${errorMessage}`);
  } finally {
    showModal.value = false;
  }
};

const calleeDelete = async (id) => {
  if (window.confirm("Are you sure you want to delete this phone number?")) {
    try {
      await axios.delete(`${BACK_URL.value}/callee/callee_delete/${id}`);
      callee.value = {};
      await settings.fetchCallee();
      router.push(`/callee`);
    } catch (error) {
      console.error("Failed to delete phone number:", error.response?.data?.detail[0]?.msg || error.message);
      alert(`Failed to delete phone number: ${error.response?.data?.detail[0]?.msg || error.message}`);
    }
  }
};

const qaListGet = async (callToId) => {
  try {
    const payload = {
      callee_id: callToId,
      caller_id: caller_id.value
    };
    const response = await axios.post(`${BACK_URL.value}/answer/qalist_get_bycalleeid`, payload, {
      withCredentials: true
    });

    qaLists.value[callToId] = JSON.stringify(response.data, null, 2);
  } catch (error) {
    console.error("Failed to load QA list:", error.message);
    qaLists.value[callToId] = `Failed to load QA list: ${error.message}`;
  }
};

const handleSaveBeforeAuth = async () => {
  await calleeUpdate(callee.value);
};

onMounted(async () => {
  await calleeGetById();
  await settings.fetchSettings();
});
</script>

<style scoped></style>
