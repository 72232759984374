<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <NavBar />
      <div class="bottom-margin"></div>
      {{callee_id}}asdf
      <div class="callee-info">
        <span class="callee-detail">Callee Name: {{ callee_name }}</span>
        <span class="callee-detail">Callee ID: {{ callee_id }}</span>
      </div>

      <div class="conversations-container">
        <template v-for="conversation in conversations" :key="conversation.conversation_id">
          <div class="conversation-item">
            <div class="conversation-header">
              <span class="timestamp">{{ formatDateTime(conversation.last_updated) }}</span>
            </div>

            <div class="messages-container">
              <template v-if="messages[conversation.conversation_id]">
                <div v-for="message in messages[conversation.conversation_id]" 
                     :key="message.message_id"
                     class="message"
                     :class="{ 'message-from': message.from_username === callee_name }">
                  <div class="message-header">
                    <span class="username">{{ message.from_username }}</span>
                    <span class="timestamp">{{ formatDateTime(message.created_time) }}</span>
                  </div>
                  <div class="message-content">
                    <div class="message-text">{{ message.message_text }}</div>
                  </div>
                  <button 
                    v-if="message.from_username !== callee_name"
                    @click="createQuestion(message)"
                    class="create-question-btn"
                  >
                    Create Question
                  </button>
                </div>
              </template>
              <div v-else class="loading-messages">Loading messages...</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { storeToRefs } from 'pinia';
import { useSettingsStore } from "../store";
import axios from "axios";
import NavBar from "./NavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";

const settings = useSettingsStore();
const { showModal, BACK_URL, callee_id, callee_name, caller_id } = storeToRefs(settings);

const conversations = ref([]);
const messages = ref({});
callee_id.value = 1

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true
  });
};

const fetchConversations = async () => {
  if (!callee_id.value || callee_id.value === 0) return;
  console.log(callee_id.value)
  callee_id.value = 43
  
  try {
    showModal.value = true;
    const response = await axios.get(`${BACK_URL.value}/ig/conversations`, {
      params: { callee_id: callee_id.value }
    });
    conversations.value = response.data;
  } catch (error) {
    console.error("Failed to fetch conversations:", error);
  } finally {
    showModal.value = false;
  }
};

const loadAllMessages = async () => {
  if (!callee_id.value || callee_id.value === 0) return;
  
  for (const conversation of conversations.value) {
    try {
      showModal.value = true;
      const response = await axios.get(
        `${BACK_URL.value}/ig/conversations/${conversation.conversation_id}/messages`,
        { params: { callee_id: callee_id.value } }
      );
      messages.value[conversation.conversation_id] = response.data;
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    } finally {
      showModal.value = false;
    }
  }
};

const createQuestion = async (message) => {
  try {
    showModal.value = true;
    const response = await axios.post(
      `${BACK_URL.value}/ig/messages/${message.message_id}/create_question`,
      {
        message_id: message.message_id,
        callee_id: callee_id.value,
        caller_id: caller_id.value,
        question_subject: `IG Reply: ${message.message_text.substring(0, 30)}...`
      }
    );
    
    if (response.data.status === 'success') {
      alert('Question created successfully!');
    }
  } catch (error) {
    console.error('Failed to create question:', error);
    alert('Failed to create question. Please try again.');
  } finally {
    showModal.value = false;
  }
};

onMounted(async () => {
  await fetchConversations();
  await loadAllMessages();
});
</script>

<style scoped>
.conversations-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.conversation-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
}

.conversation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.messages-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.message {
  padding: 0.75rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  max-width: 80%;
  position: relative;
}

.message-from {
  align-self: flex-end;
  background: rgba(66, 153, 225, 0.2);
}

.message-header {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
}

.username {
  font-weight: bold;
}

.timestamp {
  font-size: 0.9em;
}

.arrow {
  transition: transform 0.3s ease;
  font-size: 20px;
}

.arrow-down {
  transform: rotate(90deg);
}

.loading-messages {
  text-align: center;
  padding: 1rem;
}

.callee-info {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-bottom: 1rem;
}

.callee-detail {
  font-weight: bold;
}

.message-content {
  display: flex;
  align-items: flex-start;
}

.message-text {
  word-break: break-word;
}

.create-question-btn {
  position: absolute;
  right: -120px;
  top: 50%;
  transform: translateY(-50%);
  background: #4299e1;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  white-space: nowrap;
  transition: background-color 0.2s;
  width:100px;
}

.create-question-btn:hover {
  background: #3182ce;
}

.create-question-btn:active {
  background: #2b6cb0;
}
</style>